import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../styles/global.css";
import { ChakraProvider, Flex, Text } from "@chakra-ui/react";
import Landing from "./landing";
import NavBar from "../components/navBar";
import Footer from "../components/footer";
import ScrollToTop from "../components/sc";


function App() {
  const [iniColor, setIniColor] = useState("#fff")
  const isLangSet = localStorage.getItem("isLangSet")
  const [lang, setLang] = useState(isLangSet !== null ? isLangSet : "en")
  
  useEffect(() => {
      var myNav = document.getElementById("nav");
      window.onscroll = function() {
          "use strict";
          if (document.body.scrollTop >= 70 || document.documentElement.scrollTop >= 70) {
              myNav.classList.add("scBg");
              setIniColor("#667085")
          } else {
              myNav.classList.remove("scBg");
              setIniColor("#fff")
          }
      };
  }, [])

  return (
    <ChakraProvider>
      <Flex direction="column" fontSize="16px" color="#101828">
        <Router>
          <ScrollToTop>
            <Flex w="100%" direction="column">
              <Flex w="100%" id="nav" position="fixed" top="0" left="0" zIndex="1" direction="column">
                <NavBar iniColor={iniColor} setLang={setLang} lang={lang} />
              </Flex>

              <Switch>
                <Route path="/" exact><Landing lang={lang} /></Route>
                <Route><Landing lang={lang} /></Route>
              </Switch>

              <Flex w="100%"><Footer lang={lang} /></Flex>
            </Flex>
          </ScrollToTop>
        </Router>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
